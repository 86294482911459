<template>
  <video-container />
</template>

<script>
  import VideoContainer from './video.container';

  export default {
    name: 'VideoView',
    components: {
      VideoContainer,
    },
  };
</script>

<style></style>